import React from 'react';
import { BrowserRouter as Router, Routes, Route  } from 'react-router-dom';

import SideBar from './components/Sidebar';
import sidebar_menu from './constants/sidebar-menu';

import './App.css';
import Publishing from './pages/Publishing_ManagePost';
import HomePage from './pages/HomePage/HomePage';
import 'bootstrap/dist/css/bootstrap.min.css';
import CreatePost from './pages/CreatePost/CreatePost.jsx';

function App () {

  return(
    <Router>
      <div className='dashboard-container'>
        <SideBar menu={sidebar_menu} />
          
          <div className='dashboard-body'>
              <Routes>
                  <Route path="*" element={<div></div>} />
                  <Route exact path="/CreatePost" element={<CreatePost/>} />
                  <Route exact path="/" element={<HomePage/>} />
                  <Route exact path="/ManageChannels" element={< Publishing/>} />
                  <Route exact path="/Analytics" element={<div></div>} />
                  <Route exact path="/Engagement" element={<div></div>} />
                  <Route exact path="/MyAccount" element={<div></div>} />
              </Routes>
          </div>
      </div>
    </Router>
  )
}

export default App;