import React from 'react';
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import Fblogin from './Integration/FbLogin';
import { Link } from 'react-router-dom';

function ModalComponent(props) {
    return (
        <>
            <Modal show={props.show} onHide={props.handleClose} animation={false} backdrop="static">
                <Modal.Header>
                    <Modal.Title>{props.ModalHeader} Integration</Modal.Title>
                </Modal.Header>
                <Modal.Body>              
                    {props.ModalHeader === "facebook" && <Fblogin BodyData={props.ModalBody}/>}
                    {props.ModalHeader === "Attention!!!" && <p>{props.ModalBody}</p>}
                    {props.ModalHeader === "Attention!!!" && <Link to='/ManageChannels'><button className='dashbord-header-btn'> Click Here</button></Link>}
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="danger" className={props.disabled?"disabled":""} onClick={props.handleClose}>
                       X Close
                    </Button>
                </Modal.Footer>
            </Modal>
        </>
    );
}

export default ModalComponent