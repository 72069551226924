import React from "react";
import classes from '../CreatePost/styles.module.css'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faEarth } from '@fortawesome/free-solid-svg-icons'
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';


const PostFeed = props => {
    console.log(props.PostDataClone)
    const GlobeIcon = <FontAwesomeIcon icon={faEarth} />
    return (
        <>
            <div className={classes.imtcontainer}>
                {props.PostDataClone!= undefined && props.PostDataClone.map((x, index) => {
                    return <div key={index} className={classes.img}>
                        <img src={x.Profilepic} />
                    </div>
                })}
                {/* <div className={classes.img}>
                    <img src="https://www.business2community.com/wp-content/uploads/2017/08/blank-profile-picture-973460_640.png" />
                </div> */}
                <div className={classes.textarea}>
                    <p className={classes.PreviewHeader}>
                        {props.PostDataClone!= undefined && props.PostDataClone.map((x, index) => {
                            return <span key={index + 1}>{index + 1 == 1 ? x.name : ` & ${x.name}`}</span>
                        })}
                    </p>
                    <p className={classes.PreviewTime}>Published by SMM Tool.{GlobeIcon} &nbsp;&nbsp;&nbsp; Justnow. {GlobeIcon}</p>
                </div>
                <div className="col">
                    <p>{props.PostPreviewtext}</p>
                    <img className={classes.PostedImg} src={props.PostPreviewURL} />
                </div>
                <Row style={{ borderTop: "1px solid #ddd", padding: "5px", position: "absolute", bottom: "0", width: "95%", background: "#fff" }}>
                    <Col className={`col-md-4 ${classes.PostBtns}`}>Like</Col>
                    <Col className={`col-md-4 ${classes.PostBtns}`}>Comment</Col>
                    <Col className={`col-md-4 ${classes.PostBtns}`}>Share</Col>
                </Row>
            </div>
        </>
    )
}

export default PostFeed