import React from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faBlenderPhone, faCoffee, faDatabase, faHomeLgAlt, faPerson, faPersonArrowUpFromLine, faPersonBurst, faPersonCane, faPersonCircleCheck, faPersonCircleExclamation, faPersonDigging, faPersonMilitaryRifle, faPersonSkiingNordic, faSquarePersonConfined, faSquarePollVertical } from '@fortawesome/free-solid-svg-icons'
import { faUpload,faHome } from '@fortawesome/free-solid-svg-icons'
import { faChartArea } from '@fortawesome/free-solid-svg-icons'
const element = <FontAwesomeIcon icon={faCoffee} />
const PublishIcon = <FontAwesomeIcon icon={faUpload} />
const FaChartIcon = <FontAwesomeIcon icon={faChartArea} />
const EnagementIcon = <FontAwesomeIcon icon={faDatabase} />
const ProFileIcon = <FontAwesomeIcon icon={faSquarePollVertical} />
const faHomeIcon = <FontAwesomeIcon icon={faHome} />

const sidebar_menu = [
    {
        id: 0,
        icon: faHomeIcon,
        path: '/',
        title: 'Home',
    },
    {
        id: 1,
        icon: PublishIcon,
        path: '/ManageChannels',
        title: 'Publishing',
    },
    {
        id: 2,
        icon: FaChartIcon,
        path: '/Analytics',
        title: 'Analytics',
    },
    {
        id: 3,
        icon: EnagementIcon,
        path: '/Engagement',
        title: 'Enagement',
    },
    {
        id: 4,
        icon: ProFileIcon,
        path: '/MyAccount',
        title: 'My account',
    }
]

export default sidebar_menu;