import Form from 'react-bootstrap/Form';
import Accordion from 'react-bootstrap/Accordion';
import Table from 'react-bootstrap/Table';
import { useState,useRef, useEffect } from 'react';
let State = 0;
function FbPagesCheckBox(props) {

  const [rerender, setRerender] = useState(false);
  const [NewProfArrRR,setNewProfArrRR] = useState(props.PagesDatas);
  let NewProfArr = [];
  console.log(props.PagesDatas)

    const GetProfilePic = async () => {        
        await Promise.all(           
          props.PagesDatas.map(async (element,index) => {
            console.log(element)
              //alert(element.DataType);
              if(element.DataType == "Page"){
              /// alert("https://graph.facebook.com/"+element.PageId+"?fields=picture.width(720).height(720)&access_token='"+element.PageAccessToken);
               const res = await fetch("https://graph.facebook.com/"+element.id+"?fields=picture.width(720).height(720)&access_token="+element.access_token);
               const data = await res.json()
               console.log(data.picture.data.url)
               NewProfArr.push({...element,"Profilepic":data.picture.data.url})
              // console.log(NewProfArr);                 
              // console.log(NewProfArrRR)   
               document.getElementById(element.id).setAttribute("data-Profilepic",data.picture.data.url)
              }
              if(element.DataType == "Group"){
                //alert("test")
                /// alert("https://graph.facebook.com/"+element.PageId+"?fields=picture.width(720).height(720)&access_token='"+element.PageAccessToken);
                // https://graph.facebook.com/539568441191110/picture?access_token=EAAIgbn0r6R8BAJ3hbfCmZAEE6ZCRnrea21nDwOnDWKc9XJKwodXTVqjqBnxV2Hj0SPZCeKzFPirYNWX98TVlbLyYIFPjQZCZBrlYF00y4x6laI7u15VfWV3etHoGcuc0iUJUFWmt18LoTZAMY6Lq9cfB8oxHleQOG0aHSgLZA8gZCn2jZC9EQZBgLRm35jZBf7oTcPPNrAwHneLuBCIvYJrhhFIyxRCvqfm8C7xeAhfFIvO5lc05iliPi6t
                const res = await fetch("https://graph.facebook.com/"+element.id+"?fields=icon,cover&access_token="+localStorage.LongLifeAccessToken);
                 const data = await res.json()
                 console.log(data.cover.source)
                 NewProfArr.push({...element,"Profilepic":data.cover.source})
                // console.log(NewProfArr);                 
                 //console.log(NewProfArrRR)   
                 document.getElementById(element.id).setAttribute("data-Profilepic",data.cover.source)
                }
            })
        )        
        //return setSelectedPagesData(NewProfArr);
    }

  
    if(State===0){
      GetProfilePic();
      State++
    }

    


  const myRefname= useRef(null);

  const OnClickCheckHandler = (args) =>{
    //alert("click"+args);   
    document.getElementById(args).checked = document.getElementById(args).checked? false:true;
    args.stopPropagation();   
  }

  return (
     <div>
     <Form>
      
      {props.PagesDatas.map((data) => (        
        <div key={`default-${data.id}`} className="mb-3">
          <Accordion>
            <Accordion.Item eventKey="0">
              <Accordion.Header>
                <div>
                <input
                  type="checkbox"
                 // onChange={OnClickCheckHandler(()=>{alert("Check")})}
                  id={`${data.id}`}
                  label={`${data.name}`}
                  data-accessToken={`${data.access_token}`}
                  data-category={`${data.category}`}
                  data-tasks={`${data.tasks}`}
                  data-Profilepic={`${data.Profilepic}`}
                  data-DataType={`${data.DataType}`}
                  //onChange={props.IntegratePageHandler}
                  className="PagesFbCheckBox"
                  ref={myRefname}
                />
                <span onClick={()=>{OnClickCheckHandler(data.id)}} className='AccordionInputSpan'>{data.name}</span>
                </div>
              </Accordion.Header>
              <Accordion.Body>
                <Table responsive>
                  <tbody>
                    <tr>
                      <td><span>{`${data.DataType} Name:`}</span></td>
                      <td>{`${data.name}`}</td>
                    </tr>
                    <tr>
                    <td><span> {`${data.DataType} Id:`}</span></td>
                    <td>{`${data.id}`}</td>
                  </tr>
                  <tr>
                    <td><span>{`${data.DataType} Category:`}</span></td>
                    <td>{`${data.category}`}</td>
                  </tr>
                  <tr>
                    <td><span>{`${data.DataType} Tasks:`}</span></td>
                    <td>{`${data.tasks}`}</td>
                  </tr>
                  <tr>
                    <td><span>{`${data.DataType} DataType:`}</span></td>
                    <td>{`${data.DataType}`}</td>
                  </tr>
                  </tbody>
                </Table>
              </Accordion.Body>
            </Accordion.Item>
          </Accordion>
        </div>
      ))}
    </Form>
    </div>
  );
}

export default FbPagesCheckBox;