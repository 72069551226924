import React from 'react';
import { Link } from 'react-router-dom';
import './styles.css';
import NotificationIcon from '../../assets/icons/notification.svg';
import SettingsIcon from '../../assets/icons/settings.svg';


function DashboardHeader ({icon,ImageIcons, btnText, PathName, onClick }) {
    return(
        <div className='dashbord-header-container'>
            <Link to="/ManageChannels">
            {btnText && 
                <button className={ PathName ==="/ManageChannels" ? "dashbord-header-btn HeaderNavactive ":"dashbord-header-btn"} onClick={onClick}>{icon} {btnText}</button>
            } 
            </Link>
            <Link to={"/CreatePost"}>
             {btnText && 
                <button className={ PathName ==="/CreatePost" ? "dashbord-header-btn HeaderNavactive ":"dashbord-header-btn"} onClick={onClick}>{ImageIcons} Create Post</button>
            }
            </Link>

            <div className='dashbord-header-right'>
                <img 
                    src={NotificationIcon}
                    alt='notification-icon'
                    className='dashbord-header-icon' />
                <img 
                    src={SettingsIcon}
                    alt='settings-icon'
                    className='dashbord-header-icon' />
                <img
                    className='dashbord-header-avatar'
                    src='https://reqres.in/img/faces/9-image.jpg' />
            </div>
        </div>
    )
}

export default DashboardHeader;