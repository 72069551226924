import { useEffect } from 'react';
import { useState, useRef } from 'react';
import EmojiPicker from '../../constants/EmojiPicker';
import classes from '../CreatePost/styles.module.css'
import Multiselect from 'multiselect-react-dropdown';

let GetLocalVal;


    if (localStorage.IntegratedPagesData != undefined && localStorage.IntegratedPagesData != null && localStorage.IntegratedPagesData != "") {
        GetLocalVal = JSON.parse(localStorage.IntegratedPagesData)
        
    } else {
        GetLocalVal = [];
    }

    console.log(GetLocalVal)



const PostBox = props => {

    const [NewPagesData, setNewPagesData] = useState(GetLocalVal)
    const [SelectedPagesData, setSelectedPagesData] = useState(GetLocalVal)
    const [rerender, setRerender] = useState(false);
    console.log(SelectedPagesData)
    // let NewProfArr = [];
    // const GetProfilePic = async () => {        
    //     await Promise.all(           
    //         SelectedPagesData.map(async (element) => {
    //            console.log(element.PageId);
    //           /// alert("https://graph.facebook.com/"+element.PageId+"?fields=picture.width(720).height(720)&access_token='"+element.PageAccessToken);
    //            const res = await fetch("https://graph.facebook.com/"+element.PageId+"?fields=picture.width(720).height(720)&access_token="+element.PageAccessToken);
    //            const data = await res.json()
    //            console.log(data.picture.data.url)
    //            NewProfArr.push({...element,"Profilepic":data.picture.data.url})
    //            console.log(NewProfArr);               
    //         })
    //     )        
    //     //return setSelectedPagesData(NewProfArr);
    // }
    // GetProfilePic();





    const inputFile = useRef(null);
    let PagesLocalData = localStorage.IntegratedPagesData;

    if (PagesLocalData != undefined) {
        PagesLocalData = JSON.parse(PagesLocalData);
        ///console.log(PagesLocalData);
    }
    const [PagesDatas, setPagesDatas] = useState("");
    const [EmojiClickStatus, setEmojiClickStatus] = useState(false);
    useEffect(() => {
        setPagesDatas(PagesLocalData);

    }, []);




    const FileInput = () => {
        //alert();
        inputFile.current.click();
    }

    const EmojiClckHandler = () => {
        if (EmojiClickStatus) {
            setEmojiClickStatus(false);
        } else {
            setEmojiClickStatus(true)
        }
    }

    const [field, setField] = useState([]);

    const onRemove = (e) => {
        console.log(e)
        props.SlectedpagesFunc(e);
        if (e.length == 0) {
            alert("You Cont remove All Pages Atleast One Page need selected.");
            return
        }
        else {
            if (e.length == 1) {
                //alert("Only One Left Disable it");
                document.querySelector(".closeIcon").style.pointerEvents = "none";
            } else {
                let Arr = document.querySelectorAll(".closeIcon");
                for (let index = 0; index < Arr.length; index++) {
                    const element = Arr[index];
                    element.style.pointerEvents = "auto"
                }
            }
            setSelectedPagesData(e);
            setSelectedPagesData(e);
            if (!rerender) {
                setRerender(true);
            } else {
                setRerender(false);
            }

        }
    }

    const onSelect = (e) => {
        console.log(e);
        props.SlectedpagesFunc(e);
        if (e.length == 1) {
            //  alert("Only One Left Disable it");
            document.querySelector(".closeIcon").style.pointerEvents = "none";
            setSelectedPagesData(e);
        } else {
            let Arr = document.querySelectorAll(".closeIcon");
            for (let index = 0; index < Arr.length; index++) {
                const element = Arr[index];
                element.style.pointerEvents = "auto";
            }
            setSelectedPagesData(e);

            if (!rerender) {
                setRerender(true);
            } else {
                setRerender(false);
            }

        }

    }

    useEffect(() => {
        let OnloadVar = SelectedPagesData;
        props.SlectedpagesFunc(OnloadVar)
    }, [])

    const [ImgUrl,setImgUrl] = useState(""); 

    return (
        <>
            <div className={classes.mainDiv}>

                <div id="body">
                    <div className={classes.imtcontainer}>
                        {SelectedPagesData != undefined && SelectedPagesData != null && SelectedPagesData.map((x,index) => {
                            return <div key={x.PageId} className={classes.img}>
                                <img src={x.Profilepic} />                              
                            </div>
                        })}
                        <div className={classes.textarea}>
                            <textarea onChange={props.KeyPressPostHandler} value={props.PostPreviewtext} placeholder="what's on you mind?"></textarea>
                        </div>


                    </div>

                    <div className={classes.line}>
                        <a onClick={EmojiClckHandler}><i className="fa fa-smile-o" aria-hidden="true"></i></a>
                        {EmojiClickStatus && <EmojiPicker style={{zIndex:"99999"}} onEmojiClick={props.onEmojiClick} />}
                    </div>



                    <div className={classes.icons}>
                        <div className={`col-md-6 ${classes.photos}`} >
                            <a href="#" onClick={FileInput}>
                                <input ref={inputFile} onChange={props.FileSelectPostHandler} style={{ display: "none" }} type="file" />
                                <i className="fa fa-picture-o" aria-hidden="true"></i>
                                Photo/Video</a>
                        </div>

                        <div className={`col-md-6 ${classes.feeling}`}>
                            <a href='#'>
                                <i className="fa fa-smile-o" aria-hidden="true"></i>
                                Felling/Activity</a>
                        </div>



                        <div style={{ display: 'inline-block' }} className={`col-md-6 ${classes.checkin}`}>
                            <a href='#'>
                                <i style={{ color: '#f42a67' }} className="fa fa-map-marker" aria-hidden="true"></i>
                                Check in</a>
                        </div>

                        <div style={{ display: 'inline-block' }} className={`col-md-6 ${classes.tagfriend}`}>
                            <a href='#'>
                                <i style={{ color: '#90c0d5' }} className="fa fa-user-plus" aria-hidden="true"></i>
                                Tag Friend</a>
                        </div>

                        <div style={{ display: 'inline-block' }} className={`col-md-6 ${classes.live}`}>
                            <a href='#'>
                                <i style={{ color: '#fa3e3e' }} className="fa fa-video-camera" aria-hidden="true"></i>
                                Live Video</a>
                        </div>


                        <div style={{ display: 'inline-block' }} className={`col-md-6 ${classes.back}`}>
                            <a href='#'>
                                <i style={{ color: '#f7923c' }} className="fa fa-fonticons" aria-hidden="true"></i>
                                Background Color</a>
                        </div>

                        <div className='col-md-12'> <br></br>
                            <input className='form-control' onKeyPress={props.FileSelectPostHandlerOptional} type="text" placeholder="Image URL (Optional*)"></input>
                        </div>

                        <div style={{ display: 'inline-block' }} className={classes.footer}>
                            <a href="#" style={{ display: ' none' }}>
                                <i className="fa fa-users" aria-hidden="true"></i>
                                Add friends except..<i className="fa fa-caret-down"></i>
                            </a>

                            <Multiselect
                                options={NewPagesData} // Options to display in the dropdown
                                selectedValues={NewPagesData} // Preselected value to persist in dropdown
                                onSelect={onSelect} // Function will trigger on select event
                                onRemove={onRemove} // Function will trigger on remove event
                                displayValue="name" // Property name to display in the dropdown options
                            />


                            {/* <Form.Group className="mb-3">
                                <Form.Select >
                                    <option>Select Pages to Post</option>
                                    {NewPagesData != undefined && NewPagesData != null && NewPagesData != "" && NewPagesData.map(x => {
                                        console.log(x.name)
                                        return <opt ion key={x.PageId} >{<Form.Check type="checkbox" label={x.name} />}</opt>
                                    })}
                                </Form.Select>
                            </Form.Group> */}
                            <br></br>
                            <a style={{ cursor: "pointer" }} onClick={() => { props.PublishDataFunc() }}>Post</a>
                        </div>


                    </div>
                </div>

            </div>

        </>
    )
}

export default PostBox