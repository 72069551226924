
import classes from '../HomePage/HomePage.module.css'
import React, { useState, useEffect } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import DashboardHeader from '../../components/DashboardHeader';

import { faEarth } from '@fortawesome/free-solid-svg-icons'
import { faImage } from '@fortawesome/free-solid-svg-icons'

const HomePage = props => {
    const GlobeIcon = <FontAwesomeIcon icon={faEarth} />
    const ImageIcon = <FontAwesomeIcon icon={faImage} />
    return (
        <>
            <div className={`col-md-12 ${classes.padding20}`}>
                <DashboardHeader />
            </div>
            <div className={`col-md-12 ${classes.padding20}`}>
                <div className="container-fluid">
                    <div className={`row ${classes.contentcontainer}`}>
                        <div className={`col-md-6 ${classes.padding20}`}>
                            <h2 className={classes.Header}>Welcome To SMM Tool.</h2><br></br>
                            <p>Social media management involves properly handling your presence on all of the social platforms your brand (whether that’s a big corporation, small business or just you) uses on a daily basis.
                                <br /><br />Managing social media includes planning and scheduling posts, interacting with followers, answering queries, keeping up with current trends, and analyzing your performance.
                            </p>
                            <div>
                                In this Application we are going to link the following social media platforms :
                                <ul>
                                    <li>Facebook</li>
                                    <li>Instagram</li>
                                    <li>Whatsapp</li>
                                    <li>Twitter</li>
                                    <li>Pinterest</li>
                                    <li>LinkedIn</li>
                                    <li>Google My Business</li>
                                    <li>Snapchat</li>
                                    <li>Tiktok</li>
                                </ul>
                            </div>
                        </div>
                        <div className="col-md-6">
                            {/* <img src='https://png.pngtree.com/thumb_back/fh260/background/20220427/pngtree-smm-banner-high-roi-content-image_1091551.jpg' width="100%" /> */}
                            <img src='https://www.warrington-worldwide.co.uk/wp-content/uploads/2020/05/social-media.png' width="100%" />
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}
export default HomePage