import React, { useState } from 'react';
import Picker from 'emoji-picker-react';
import './EmojiCss.css'

const EmojiPicker = (props) => {
  
  return (
    <div>
      <Picker onEmojiClick={props.onEmojiClick} pickerStyle={{ width: '100%' }} />
      
    </div>
  );
};

export default EmojiPicker