import React, { useState, useEffect } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import DashboardHeader from '../../components/DashboardHeader';
import { faEarth } from '@fortawesome/free-solid-svg-icons'
import { faImage } from '@fortawesome/free-solid-svg-icons'
import Button from 'react-bootstrap/Button';
import classes from '../CreatePost/styles.module.css'
import ModalComponent from '../../components/model';
import Form from 'react-bootstrap/Form';
import { useLocation } from 'react-router-dom';
import PostBox from './Postbox';
import { Link } from 'react-router-dom';
import PostFeed from './PostFeed';

import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';


function CreatePost() {
    ///console.log(window.location.pathname);
    const PathName = window.location.pathname
    const GlobeIcon = <FontAwesomeIcon icon={faEarth} />
    const ImageIcon = <FontAwesomeIcon icon={faImage} />
    const [ModalHeader, setModalHeader] = useState("");
    const [ModalBody, setModalBody] = useState("");
    const [ClidkedBtn, setClidkedBtn] = useState("");
    const [modalShow, setmodalShow] = useState(false);
    const [show, setShow] = useState(false);
    const handleClose = () => setShow(false);

    const [PostPreviewtext, setPostPreviewtext] = useState("");
    const [PostPreviewURL, setPostPreviewURL] = useState("");
    const [PostAccesstoken, setPostAccesstoken] = useState("");
    const [PostPageId, setPostPageId] = useState("");

    let IntegratedPagesData = localStorage.IntegratedPagesData;
    if (IntegratedPagesData != undefined) {
        //alert("not");
        IntegratedPagesData = JSON.parse(IntegratedPagesData);
        ///console.log(IntegratedPagesData);
    }

    useEffect(() => {
        if (IntegratedPagesData == undefined || IntegratedPagesData.length == 0) {
            // alert("Testt");            
            setmodalShow(true);
            handleShow();
        } else {
            IntegratedPagesData.map((item, i) => {
                // alert(item.PageId)
                setPostPageId(item.PageId);
                setPostAccesstoken(item.PageAccessToken);
            });
        }
    }, [])

    const handleShow = (Name) => {
        setShow(true);
        setModalHeader("Attention!!!");
        setClidkedBtn(Name);
        setModalBody("Login with Facebook Account To integrate");
    }


    const KeyPressPostHandler = (e) => {
        ///console.log(e.target.value);
        setPostPreviewtext(e.target.value);
        // alert("KeyPressPostHandler");
    }

    const [chosenEmoji, setChosenEmoji] = useState("");
    const onEmojiClick = (event, emojiObject) => {
        //alert("Test");
        setChosenEmoji(emojiObject.emoji);
        //let NewText = PostPreviewtext + chosenEmoji;
        // setPostPreviewtext(NewText);
    };



    const FileSelectPostHandlerOptional = (e) => {
        // alert("option") 
        ///console.log(e.target.value);
        if (e.target.value) {
            let imgUrl = e.target.value;
            setPostPreviewURL(imgUrl);
        }
    }


    const FileSelectPostHandler = (e) => {
        ///console.log(e.target.value);
        let imgUrl = URL.createObjectURL(e.target.files[0]);
        setPostPreviewURL(imgUrl);
    }


    const PublishDataFunc = (e) => {
        // alert("Text");
        console.log(PostDataClone);

        for (var i = 0; i < PostDataClone.length; i++) {
            console.log(PostDataClone[i].PageId);
            console.log(PostDataClone[i].PageAccessToken);
            //alert(PostDataClone[i].DataType)
            if (PostDataClone[i].DataType == "Page") {
                //alert("Pages Posting")
                if (PostPreviewURL != "") {
                    fetch("https://graph.facebook.com/" + PostDataClone[i].PageId + "/photos?url=" + PostPreviewURL + "&message=" + PostPreviewtext + "&access_token=" + PostDataClone[i].PageAccessToken + "", {
                        method: 'POST',
                        headers: {
                            'Content-Type': 'application/json',
                        },
                        // body: JSON.stringify(data),
                    })
                        .then((response) => response.json())
                        .then((data) => {
                            alert('Success:', data);
                        })
                        .catch((error) => {
                            alert('Error:', error);
                        });
                } else {
                    fetch("https://graph.facebook.com/" + PostDataClone[i].PageId + "/feed?message=" + PostPreviewtext + "&access_token=" + PostDataClone[i].PageAccessToken + "", {
                        method: 'POST',
                        headers: {
                            'Content-Type': 'application/json',
                        },
                        // body: JSON.stringify(data),
                    })
                        .then((response) => response.json())
                        .then((data) => {
                            alert('Success:', data);
                        })
                        .catch((error) => {
                            alert('Error:', error);
                        });
                }
            }
            if (PostDataClone[i].DataType == "Group") {
                //alert("Group Posting")
                if (PostPreviewURL != "") {
                    fetch("https://graph.facebook.com/" + PostDataClone[i].PageId + "/photos?url=" + PostPreviewURL + "&message=" + PostPreviewtext + "&access_token=" + localStorage.LongLifeAccessToken + "", {
                        method: 'POST',
                        headers: {
                            'Content-Type': 'application/json',
                        },
                        // body: JSON.stringify(data),
                    })
                        .then((response) => response.json())
                        .then((data) => {
                            alert('Success:', data);
                        })
                        .catch((error) => {
                            alert('Error:', error);
                        });
                } else {
                    fetch("https://graph.facebook.com/" + PostDataClone[i].PageId + "/feed?message=" + PostPreviewtext + "&access_token=" + localStorage.LongLifeAccessToken + "", {
                        method: 'POST',
                        headers: {
                            'Content-Type': 'application/json',
                        },
                        // body: JSON.stringify(data),
                    })
                        .then((response) => response.json())
                        .then((data) => {
                            alert('Success:', data);
                        })
                        .catch((error) => {
                            alert('Error:', error);
                        });
                }
            }

        }//for ends        
    }

    useEffect(() => {
        let NewText = PostPreviewtext + chosenEmoji;
        setPostPreviewtext(NewText);
        console.log(NewText);
    }, [chosenEmoji]);

    const [PostDataClonetf, setPostDataClonetf] = useState(false);
    const [PostDataClone, setPostDataClone] = useState([]);
    const SlectedpagesFunc = (data) => {
        //alert("ttt")
        console.log(data)
        setPostDataClone(data)
        if (!PostDataClonetf) {
            setPostDataClonetf(true)
        } else {
            setPostDataClonetf(false)
        }
    }




    return (
        <div className='dashboard-content'>
            <ModalComponent handleClose={handleClose} disabled={true} handleShow={handleShow} show={show} ModalHeader={ModalHeader} ModalBody={ModalBody} />
            <DashboardHeader icon={GlobeIcon} PathName={PathName} ImageIcons={ImageIcon} btnText="Manage Channels" />

            <div className='dashboard-content-container'>
                <div className='dashboard-content-header'>

                    <div className="container-fluid">
                        <Row>
                            <Col className={`col-md-4`}> <h2>Create Post</h2></Col>
                            <Col className={`col-md-4`}></Col>
                            <Col className={`col-md-4`}></Col>
                        </Row>
                    </div>
                </div>
                <div className="container-fluid">

                    <Row>
                        <Col className={`col-md-4`}>
                            <div className={classes.CreatPostCard}>
                                <PostBox onEmojiClick={onEmojiClick} SlectedpagesFunc={SlectedpagesFunc} KeyPressPostHandler={KeyPressPostHandler} PostPreviewtext={PostPreviewtext} FileSelectPostHandlerOptional={FileSelectPostHandlerOptional} FileSelectPostHandler={FileSelectPostHandler} PublishDataFunc={PublishDataFunc} />
                            </div>
                        </Col>
                        <Col className={`col-md-1`}></Col>
                        <Col className={`col-md-4 ${classes.CreatPostCard}`} style={{ position: "relative" }}>
                            <PostFeed PostDataClone={PostDataClone} PostPreviewtext={PostPreviewtext} PostPreviewURL={PostPreviewURL} />
                        </Col>
                    </Row>

                    <br></br><br></br><br></br>
                </div>
            </div>

        </div>
    )
}

export default CreatePost;