import React, { useEffect, useState } from 'react';
import FacebookLogin from 'react-facebook-login';
import FbPagesCheckBox from '../FbPagesCheckBox';
import Button from 'react-bootstrap/Button';
import Card from 'react-bootstrap/Card';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCheckSquare, faEarth } from '@fortawesome/free-solid-svg-icons'
import Form from 'react-bootstrap/Form';
import "../../pages/styles.css"

const Fblogin = props => {
    // alert(props.BodyData)
    const RightTick = <FontAwesomeIcon icon={faCheckSquare} />
    const [autoLoad, setautoLoad] = useState(false);
    const [CheckLoginStatus, setCheckLoginStatus] = useState(false);
    const [CheckLoginStatusText, setCheckLoginStatusText] = useState(false);
    const [GetUserData, setGetUserData] = useState(false);
    const [ShowPagesData, setShowPagesData] = useState(false);
    const [Pageslinked, setPageslinked] = useState(false);

    const [PageslinkedProfile, setPageslinkedProfile] = useState(false);

    const [IngareationCheckSelected, setIngareationCheckSelected] = useState(null);

    const IngareationCheckSelectedFunc = (Data) => {
        setIngareationCheckSelected(Data)
    }
    let FinlaPagesData = [];

    // const FB = window.FB;
    const responseFacebook = (response) => {
        if (IngareationCheckSelected == null) {
            alert("Please Select option for Integration");
        } else {
            console.log(response);
            console.log(response.accessToken);
            localStorage.ShortLifeAccessToken = response.accessToken
            localStorage.UserID = response.userID;
            localStorage.name = response.name;
            localStorage.picture = response.picture.data.url;
            window.FB.getLoginStatus(function (response) {
                console.log(response);

                if (response.status === "connected") {
                    setCheckLoginStatus(true);
                    setCheckLoginStatusText(true);
                    console.log("https://graph.facebook.com/oauth/access_token?grant_type=fb_exchange_token&client_id=762465801649648&client_secret=c150a2982eecdf737a1c5fda59d12d42&fb_exchange_token=" + localStorage.ShortLifeAccessToken);
                    fetch(`https://graph.facebook.com/oauth/access_token?grant_type=fb_exchange_token&client_id=762465801649648&client_secret=c150a2982eecdf737a1c5fda59d12d42&fb_exchange_token=${localStorage.ShortLifeAccessToken}`)
                        .then((res) => res.json())
                        .then((data) => {
                            console.log(data);
                            localStorage.LongLifeAccessToken = data.access_token;
                            fetch(`https://graph.facebook.com/${localStorage.UserID}/accounts?access_token=${localStorage.LongLifeAccessToken}`)
                                .then((PagesRes) => PagesRes.json())
                                .then((PagesData) => {
                                    console.log(PagesData.data);
                                    let NewPageData = [];
                                    PagesData.data = PagesData.data.forEach(element => {
                                        console.log(element);
                                        element.DataType = "Page";
                                        NewPageData.push(element)
                                        // console.log(NewPageData);
                                    });
                                    FinlaPagesData.push(NewPageData);
                                    //alert(IngareationCheckSelected)
                                    if (IngareationCheckSelected === "Pages") {
                                        localStorage.PageDatas = JSON.stringify(NewPageData);

                                        setPageslinkedProfile(NewPageData)
                                        setCheckLoginStatusText(false);
                                        setGetUserData(true)
                                    }
                                });

                            fetch(`https://graph.facebook.com/${localStorage.UserID}/groups?access_token=${localStorage.LongLifeAccessToken}`)
                                .then((GroupsRes) => GroupsRes.json())
                                .then((GroupsData) => {
                                    console.log(GroupsData.data);
                                    //alert(IngareationCheckSelected)

                                    let NewPageData = [];
                                    GroupsData.data = GroupsData.data.forEach(element => {
                                        console.log(element);
                                        element.DataType = "Group";
                                        NewPageData.push(element)
                                        // console.log(NewPageData);
                                    });
                                    FinlaPagesData.push(NewPageData);
                                    if (IngareationCheckSelected === "Groups") {
                                        localStorage.PageDatas = JSON.stringify(NewPageData);

                                        setPageslinkedProfile(NewPageData)
                                        setCheckLoginStatusText(false);
                                        setGetUserData(true)
                                    }
                                });
                        });
                }
            });
        }
    }


    const componentClicked = (response) => {
        console.log(response);
    }

    const ContinueProfileHandler = () => {
        setShowPagesData(true);
        setGetUserData(false);
    }

    const NotMyACCHandler = () => {
        setCheckLoginStatus(false);
        setGetUserData(false);
        this.FB.logout(function (response) {
            alert("Logout Successfull");
            localStorage.clear();
            this.FB.getLoginStatus(function (response) {
                console.log(response);
            });
        });
    }




    const IntegratePageHandler = (e) => {
        let PagesDataAll = [];
        let PagesData = [];
        // alert("DATA SAVED");
        //  console.log(e.target)
        let ClassesCheck = document.querySelectorAll(".PagesFbCheckBox");
        for (let k = 0; k < ClassesCheck.length; k++) {
            //console.log(ClassesCheck[k]);
            if (ClassesCheck[k].checked) {
                //alert("If");              
                let PageName = ClassesCheck[k].getAttribute('label');
                let PageId = ClassesCheck[k].getAttribute('id');
                let PageAccessToken = ClassesCheck[k].getAttribute('data-accesstoken');
                let Pagecategory = ClassesCheck[k].getAttribute('data-category');
                let Pagetasks = ClassesCheck[k].getAttribute('data-tasks');
                let ProfilepicData = ClassesCheck[k].getAttribute('data-Profilepic');
                let DataType = ClassesCheck[k].getAttribute('data-DataType');

                PagesData.push({
                    name: PageName,
                    PageId: PageId,
                    PageAccessToken: PageAccessToken,
                    Pagecategory: Pagecategory,
                    Pagetasks: Pagetasks,
                    Profilepic: ProfilepicData,
                    DataType: DataType
                });

                //alert(PagesData);
                PagesDataAll.push(PagesData);
                //alert(ShowPagesData);
            }
        }// for ends
        console.log(PagesDataAll[0])
        let NewArr = JSON.stringify(PagesDataAll[0]);
        let PrevData = [];
        if (localStorage.IntegratedPagesData != undefined && localStorage.IntegratedPagesData != null && localStorage.IntegratedPagesData.length != 0) {
            PrevData = JSON.parse(localStorage.IntegratedPagesData);
        }

        for (let index = 0; index < PagesDataAll[0].length; index++) {
            const element = PagesDataAll[0][index];
            console.log(element)
            PrevData.push(element)
        }
        console.log(PrevData)

        const uniqueIds = [];
        const unique = PrevData.filter(element => {
            const isDuplicate = uniqueIds.includes(element.PageId);
            if (!isDuplicate) {
                uniqueIds.push(element.PageId);
                return true;
            }
            return false;
        });
        console.log(unique)
        localStorage.IntegratedPagesData = JSON.stringify(unique);
        setShowPagesData(false);
        setPageslinked(true);
    }
    return (
        <>
            {CheckLoginStatus && <Card className='LoginCard'>
                <Card.Img className='ProfilePicClass' variant="top" src={localStorage.picture} />
                <Card.Body style={{ textAlign: 'center' }}>
                    <Card.Title className='LoginCardTitle'>
                        {CheckLoginStatus && GetUserData && <p>Welcome {localStorage.name}</p>}
                        {CheckLoginStatus && ShowPagesData && <p>{localStorage.name}</p>}
                        {CheckLoginStatus && Pageslinked && <p>{localStorage.name}</p>}
                    </Card.Title>
                    <Card.Text>
                        {CheckLoginStatus && GetUserData && <p>Please Click Continue If this is Your Profile.</p>}
                        {CheckLoginStatus && ShowPagesData && <p>Please Check the checkbox The page that you want to Integrate with SMM Tool.</p>}

                    </Card.Text>
                    {CheckLoginStatus && GetUserData &&
                        <Button variant="primary" className='LoginBtns' onClick={ContinueProfileHandler} id='ContinueProfile'><i className="fa-solid fa-user"></i> Continue</Button>
                    }
                    {CheckLoginStatus && GetUserData &&
                        <Button variant="primary" className='LoginBtns' onClick={NotMyACCHandler} id='NotMyACC'><i className="fa-solid fa-magnifying-glass"></i> Not My Account</Button>
                    }

                    {CheckLoginStatus && ShowPagesData && <FbPagesCheckBox IntegratePageHandler={IntegratePageHandler} PagesDatas={JSON.parse(localStorage.PageDatas)} />}

                    {CheckLoginStatus && ShowPagesData && <Button variant="primary" onClick={IntegratePageHandler} className='LoginBtns' id='IntegratePage'><i className="fa-solid fa-check"></i> Integrate Page</Button>}
                    {Pageslinked && <p><span style={{ fontSize: 18, color: "green" }}>{RightTick}</span> Successfully Accounts Integrated <br></br> with SMM Tool.</p>}
                </Card.Body>
            </Card>}
            {!CheckLoginStatus && <p><b>{props.BodyData}</b></p>}
            {!CheckLoginStatus && <div>
                <Form.Group className="mb-3 NewCheckBox">
                    <Form.Check
                        type="radio"
                        name="group1"
                        id="CheckRadio1"
                        data-type="Pages"
                        value="Pages"
                        label="Pages integration"
                        onChange={() => { IngareationCheckSelectedFunc("Pages") }}
                    />
                </Form.Group>
                <Form.Group className="mb-3 NewCheckBox">
                    <Form.Check
                        type="radio"
                        name="group1"
                        id="CheckRadio2"
                        data-type="Groups"
                        value="Groups"
                        label="Groups integration"
                        onChange={() => { IngareationCheckSelectedFunc("Groups") }}
                    />
                </Form.Group>
            </div>}
            {!CheckLoginStatus && <FacebookLogin appId="762465801649648" autoLoad={autoLoad} fields="name,email,picture" onClick={componentClicked} callback={responseFacebook} />}
            {CheckLoginStatus && CheckLoginStatusText && <p>Facebook Login Successful.</p>}
        </>
    )
}

export default Fblogin