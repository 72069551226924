import React, { useState, useEffect } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import DashboardHeader from '../../components/DashboardHeader';
import { faEarth } from '@fortawesome/free-solid-svg-icons'
import { faImage } from '@fortawesome/free-solid-svg-icons'
import Button from 'react-bootstrap/Button';
import '../styles.css';

import ModalComponent from '../../components/model';





function Publishing() {
    const PathName = window.location.pathname
    const GlobeIcon = <FontAwesomeIcon icon={faEarth} />
    const ImageIcon = <FontAwesomeIcon icon={faImage} />
    const [ModalHeader, setModalHeader] = useState("");
    const [ModalBody, setModalBody] = useState("");
    const [ClidkedBtn, setClidkedBtn] = useState("");
    const [show, setShow] = useState(false);
    const handleClose = () => {
        setShow(false);
        setChooseshow(false);
    };
    const handleShow = (Name) => {
        setShow(true);
        setClidkedBtn(Name);
        if(Name == "facebook"){
            setModalHeader("facebook");
            setModalBody("Login with Facebook Account To integrate");
        }       
    }


    const handleShowChoose = (Name) => {
        setChooseshow(true);
        setClidkedBtn(Name);
        if(Name == "facebook"){
            setChooseGroupOrPageHeader("facebook");
            setChooseGroupOrPageBody("Choose the groups or pages to integrate");
        }       
    }

    const [Chooseshow, setChooseshow] = useState(false);
    const [ChooseGroupOrPageHeader, setChooseGroupOrPageHeader] = useState("");
    const [ChooseGroupOrPageBody, setChooseGroupOrPageBody] = useState("");




    return (
        <div className='dashboard-content'>
             <ModalComponent handleClose={handleClose} handleShowChoose={handleShow} show={Chooseshow} ModalHeader={ChooseGroupOrPageHeader} ModalBody={ChooseGroupOrPageBody} />
            <ModalComponent handleClose={handleClose} handleShow={handleShow} show={show} ModalHeader={ModalHeader} ModalBody={ModalBody} />
            <DashboardHeader PathName={PathName} icon={GlobeIcon} ImageIcons={ImageIcon} btnText="Manage Channels" />

            <div className='dashboard-content-container'>
                <div className='dashboard-content-header'>
                    <h2>Manage Your Social Channels</h2>
                </div>
                <div className="container-fluid">
                    <div className="row">
                        <div className="col">
                            <p>* You Can Manage Facebook, Instagram, Twitter And More social media Pages By Linking your Page in SMM Tool.</p>
                            <button className='btn SocialBtn facebook' onClick={()=>{handleShowChoose("facebook")}} ><i className="fa-brands fa-square-facebook faFontSize20"></i><span className='SocialSpan'>Facebook</span></button>
                            <button className='btn SocialBtn instagram'><i className="fa-brands fa-instagram faFontSize20"></i> <span className='SocialSpan'>Instagram</span></button>
                            <button className='btn SocialBtn twitter'><i className="fa-brands fa-square-twitter faFontSize20"></i><span className='SocialSpan'>Twitter</span></button>
                            <button className='btn SocialBtn whatsapp'><i className="fa-brands fa-whatsapp faFontSize20"></i><span className='SocialSpan'>Whatsapp</span></button>
                            <button className='btn SocialBtn LinkedIn'><i className="fa-brands fa-linkedin faFontSize20"></i><span className='SocialSpan'>LinkedIn</span></button>
                            <button className='btn SocialBtn Pinterest'><i className="fa-brands fa-square-pinterest faFontSize20"></i><span className='SocialSpan'>Pinterest</span></button>
                        </div>
                    </div>
                </div>
            </div>

        </div>
    )
}

export default Publishing;